import React, { useEffect } from "react"
import { Button, Col, Form, Input, message, Modal, Row, Table, Tag } from "antd"

import LocalizedAniLink from "../../components/localizedAniLink"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import Template from "../../layouts/base"
import { navigate, useIntl } from "gatsby-plugin-intl"
import withAuth from "../../components/withAuthHoc"
import authFetch from "../../services/network"
import { checkPermissions } from "../../services/auth/permissions/permissions"
import useStateObject from "../../hooks/useStateObject"
import deleteIcon from "../../assets/images/ingests/ic-delete-on.png"
import { getUser } from "../../services/auth"

const { confirm } = Modal
const { Search } = Input

const UserList = () => {
  const intl = useIntl()
  const [state, setState] = useStateObject({
    users: [],
    usersFiltered: [],
    loadingTable: true,
    searchText: "",
    loggedUser: null,
  })

  useEffect(() => {
    getUser().then(loggedUser => {
      authFetch(`${process.env.GATSBY_CONF_API_URL}/users`)
        .then(response => {
          callbackUsers(response, loggedUser)
        })
        .catch(error => {
          callbackUsersError(error)
        })
    })
  }, [])

  const callbackUsers = (response, loggedUser) => {
    if (response.status !== 200) {
      throw `Looks like there was a problem. Status Code: ${response.status}`
    }
    if (response.status === 403) {
      message.error(
        intl.formatMessage({
          id: "no-permissions-placeholder",
        })
      )
    }
    response.json().then(data => {
      setState({
        users: parseUsers(data),
        usersFiltered: parseUsers(data),
        loadingTable: false,
        loggedUser,
      })
    })
  }

  const callbackUsersError = () => {
    message.error(
      intl.formatMessage({
        id: "page-user-get-request-error-text",
      })
    )
    setState({ loadingTable: false })
  }

  const parseUsers = data => {
    let usersFormatted = []
    if (data && data.length > 0) {
      usersFormatted = data.map(user => ({
        id: user.id ? user.id : "",
        name: user.name ? user.name : "",
        last_name: user.last_name ? user.last_name : "",
        email: user.email ? user.email : "",
        avatar: {
          backgroundColor: getRandomLightColor(),
          text:
            user.name && user.name.length > 0
              ? user.name.charAt(0).toUpperCase()
              : user.email.charAt(0).toUpperCase(),
        },
        roles: user.roles && user.roles.length > 0 ? user.roles : [],
      }))
    }
    return usersFormatted
  }

  const getRandomLightColor = () => {
    return (
      "rgb(" +
      (Math.floor(Math.random() * 56) + 200) +
      ", " +
      (Math.floor(Math.random() * 56) + 200) +
      ", " +
      (Math.floor(Math.random() * 56) + 200) +
      ")"
    )
  }

  const onRowDelete = user => {
    confirm({
      title: intl.formatMessage({ id: "modal-are-you-sure" }),
      content: intl.formatMessage(
        {
          id: "page-user-delete-user-modal-text",
        },
        { name: user.name, last_name: user.last_name }
      ),
      okText: intl.formatMessage({ id: "modal-ok" }),
      okType: "danger",
      cancelText: intl.formatMessage({ id: "modal-ko" }),
      onOk() {
        authFetch(`${process.env.GATSBY_CONF_API_URL}/users/${user.id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(response => callbackRemoveUser(response, user))
          .catch(error => callbackRemoveUserError(error))
      },
      onCancel() {
        message.info(intl.formatMessage({ id: "modal-delete-cancel" }))
      },
    })
  }

  const callbackRemoveUser = (response, user) => {
    if (response.status !== 204) {
      message.error(
        intl.formatMessage({
          id: "page-user-delete-user-error-text",
        })
      )
    } else {
      const newUsers = state.users.filter(u => user.id !== u.id)
      const usersFiltered = filterUsersBySearchText(newUsers, state.searchText)
      setState({
        users: newUsers,
        usersFiltered,
      })
      message.success(
        intl.formatMessage({
          id: "page-user-delete-user-ok-text",
        })
      )
    }
  }

  const callbackRemoveUserError = () => {
    message.error(
      intl.formatMessage({
        id: "page-user-delete-user-error-text",
      })
    )
  }

  const handleFilterUsers = e => {
    const searchText = e.currentTarget.value
    const { users } = state
    const usersFiltered = filterUsersBySearchText(users, searchText)
    setState({
      usersFiltered,
      searchText,
    })
  }

  const filterUsersBySearchText = (users, searchText) => {
    return users.filter(
      item =>
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.email.toLowerCase().includes(searchText.toLowerCase()) ||
        item.roles.some(role => searchText.toLowerCase().includes(role))
    )
  }

  const columnsTable = [
    {
      title: intl.formatMessage({ id: "name" }),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend"],
      defaultSortOrder: "ascend",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "last_name" }),
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      sortDirections: ["ascend", "descend"],
      ellipsis: true,
    },
    {
      title: intl.formatMessage({
        id: "new-ingestion-email-label",
      }),
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["ascend", "descend"],
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "roles" }),
      dataIndex: "roles",
      key: "roles",
      render: roles =>
        roles &&
        roles.length > 0 &&
        roles.map(role => (
          <Tag className="role-tag" key={role}>
            {role}
          </Tag>
        )),
    },
    {
      title: intl.formatMessage({ id: "actions" }),
      dataIndex: "actions",
      key: "actions",
      width: 120,
      align: "center",
      render: (text, user) => {
        if (user && state.loggedUser && user.id === state.loggedUser.id) {
          return null
        }
        return (
          <button
            className="button-no-styles ingest-actions__button"
            onClick={event => {
              event.stopPropagation()
              onRowDelete(user)
            }}
          >
            <img
              style={{ fontSize: "1.25rem" }}
              className="ingest-actions__button__delete"
              src={deleteIcon}
              alt="deleteIcon"
            />
          </button>
        )
      },
      ellipsis: true,
    },
  ]

  return (
    <Template selected={["user", "user-list"]}>
      <CustomBreadcrumb
        crumbs={[intl.formatMessage({ id: "menu-user-management" })]}
      />
      <Row>
        <Col className="knolar-intro">
          {intl.formatMessage({ id: "menu-user-list" })}
        </Col>
      </Row>
      <div className="content">
        <Row type="flex" justify="center" align="top">
          <Col span={12} align="left">
            <Form.Item>
              <Search
                style={{ height: "40px" }}
                placeholder={intl.formatMessage({
                  id: "page-user-search-bar-filter-text",
                })}
                onInput={handleFilterUsers}
                disabled={state.loadingTable}
              />
            </Form.Item>
          </Col>
          <Col span={12} align="right">
            {checkPermissions(["user:write"]) ? (
              <LocalizedAniLink to="/user/new-user">
                <Button type="primary" className="knolar-button">
                  {intl.formatMessage({
                    id: "page-user-create-user-button-text",
                  })}
                </Button>
              </LocalizedAniLink>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              className="ingests-table"
              showHeader={true}
              size="small"
              rowKey={item => item.id}
              columns={columnsTable}
              dataSource={state.usersFiltered}
              loading={state.loadingTable}
              pagination={{ defaultPageSize: 10 }}
              onRow={r => ({
                onClick: () => navigate(`/user/${r.id}`),
              })}
              locale={{
                filterTitle: intl.formatMessage({
                  id: "filters",
                }),
                filterConfirm: intl.formatMessage({
                  id: "apply",
                }),
                filterReset: intl.formatMessage({ id: "reset" }),
                emptyText: intl.formatMessage({ id: "no-data" }),
              }}
            />
          </Col>
        </Row>
      </div>
    </Template>
  )
}

export default withAuth(UserList)
